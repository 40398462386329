import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'

import {PageNames} from '../../../../common/constants'
import {FilterDefinition} from '../../../../common/types'
import {useBranding} from '../../../../Organisms/Branding'
import CustomersFilter from '../../../../Organisms/Customers/CustomersFilter'
import {CustomerSimpleLookup} from '../../../../Organisms/Customers/CustomerSimpleLookup'
import {useFeaturesState} from '../../../../Organisms/Features'
import {GenericFilterBar} from '../../../../Organisms/Filters/GenericFilterBar'
import {useFilters} from '../../../../Organisms/Filters/useFilters'
import {removeGlobalProjectFilter, removeGlobalSiteFilter} from '../../../../Organisms/Projects'
import ProjectsSitesFilter from '../../../../Organisms/Projects/ProjectsSitesFilter'
import {usePermissions} from '../../../../Permissions'
import {removeSiteFilter} from '../MaterialTest.filters'

import DateFilter from './DateFilter'
import LookupFilter from './LookupFilter'
import SitesFilter from './SitesFilter'

export interface FilterBarErrorMaterialTest {
  key: string
  label: string
}

interface Props {
  errors?: FilterBarErrorMaterialTest[]
  lookupAnalyticsId?: string
  userId?: string
}

const Filters: React.FC<Props> = ({lookupAnalyticsId, userId, ...props}) => {
  const branding = useBranding()
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const country = branding.country ?? ''
  const {displayCustomerSimpleLookup} = usePermissions()
  const {errors} = props
  const isLookupError = errors?.some((err) => err.key === 'noLookup')
  const {getFeature} = useFeaturesState()
  const customerSearchByNameEnabled = getFeature('CustomersByName')

  const initialFiltersDefinition: FilterDefinition[] = useMemo(
    () => [
      {
        name: 'customer-filter',
        label: t('filterMenu.customer'),
        isActive: true,
        isMandatory: true
      },
      {
        name: 'date-filter',
        label: t('filterMenu.date'),
        isActive: true,
        isMandatory: true
      },
      {
        name: 'sites-filter',
        label: t('filterMenu.sites'),
        isActive: true
      }
    ],
    []
  )

  const handleOnResetFilter = (filter: FilterDefinition) => {
    switch (filter.name) {
      case 'sites-filter': {
        dispatch(removeSiteFilter())
        dispatch(removeGlobalProjectFilter())
        dispatch(removeGlobalSiteFilter())
        break
      }
    }
  }

  const {filtersDefinition, handleOnFiltersChange, getFilterValue} = useFilters(
    'material-tests',
    initialFiltersDefinition,
    handleOnResetFilter
  )
  return (
    <GenericFilterBar
      data-test-id="materialTest-filters"
      toolbarProps={{notEndLast: true}}
      filters={filtersDefinition}
      onFiltersChange={handleOnFiltersChange}
      lookupFilter={
        <LookupFilter hasError={isLookupError} analyticsId={lookupAnalyticsId} userId={userId} />
      }
    >
      {getFilterValue('customer-filter') && (
        <>
          {displayCustomerSimpleLookup ? (
            <CustomerSimpleLookup
              data-test-id="materialTest-filters-lookup"
              customerSearchByName={customerSearchByNameEnabled}
              page={PageNames.RMC_TEST_CERTIFICATES}
            />
          ) : (
            <CustomersFilter onLight customersOnly page={PageNames.RMC_TEST_CERTIFICATES} />
          )}
        </>
      )}
      {getFilterValue('date-filter') && <DateFilter />}
      {getFilterValue('sites-filter') && (
        <>
          {['HK', 'AU'].includes(country) ? (
            <ProjectsSitesFilter onLight page={PageNames.RMC_TEST_CERTIFICATES} />
          ) : (
            <SitesFilter onLight />
          )}
        </>
      )}
    </GenericFilterBar>
  )
}

export default Filters
