import {useTranslation} from '@hconnect/uikit'
import {Moment} from 'moment'
import React, {useMemo} from 'react'

import {FilterDefinition} from '../../../common/types'
import {GenericFilterBar} from '../../../Organisms/Filters/GenericFilterBar'
import {useFilters} from '../../../Organisms/Filters/useFilters'
import {Plant} from '../../../Organisms/Plants/Plants.types'
import {PlantsDropdown} from '../../../Organisms/Plants/PlantsDropdown'
import {ProductsDropdown} from '../../../Organisms/Products/ProductsDropdown'
import {Product} from '../Cement/types'

import {DateFilter} from './DateFilter'

export interface FilterBarErrorCertificate {
  key: string
  label: string
}

export interface MaterialCertificatesFilter {
  materialName?: string
  startDate: string
  endDate: string
  skip: number
  limit: number
  plantId?: string
  sortedByKey: string
  sortedByDirection: 'asc' | 'desc'
  customerId?: string
  open: boolean
}

interface Props {
  errors?: FilterBarErrorCertificate[]
  plantsError?: Error | unknown
  plants?: Plant[]
  handlePlants: (plant: Plant) => void
  selectedPlantId?: string
  selectedProductId?: string
  handleDates: (filter: {startDate: Moment | null; endDate: Moment | null}) => void
  startDate: Moment | null
  endDate: Moment | null
  productsError?: Error | unknown
  products?: Product[]
  handleProducts: (product: Product) => void
  // mobile part
  xs?: boolean
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  numberOfItems?: number
  apply: () => void
}

export const Filters: React.FC<Props> = ({
  plantsError,
  plants = [],
  handlePlants,
  selectedPlantId,
  selectedProductId,
  handleDates,
  startDate,
  endDate,
  productsError,
  products,
  handleProducts,
  xs = false,
  isVisible,
  setIsVisible,
  numberOfItems,
  apply,
  ...props
}) => {
  const {t} = useTranslation()

  const initialFiltersDefinition: FilterDefinition[] = useMemo(
    () => [
      {
        name: 'date-filter',
        label: t('filterMenu.date'),
        isActive: true,
        isMandatory: true
      },
      {
        name: 'plants-filter',
        label: t('filterMenu.plants'),
        isActive: true
      },
      {
        name: 'products-filter',
        label: t('filterMenu.products'),
        isActive: true
      }
    ],
    []
  )

  const handleOnResetFilter = (filter: FilterDefinition) => {
    switch (filter.name) {
      case 'plants-filter': {
        handlePlants(null as any)
        break
      }

      case 'products-filter': {
        handleProducts(null as any)
        break
      }
    }
  }

  const {filtersDefinition, handleOnFiltersChange, getFilterValue} = useFilters(
    'certificates',
    initialFiltersDefinition,
    handleOnResetFilter
  )

  return (
    <GenericFilterBar
      filters={filtersDefinition}
      data-test-id="certificates-filters"
      toolbarProps={{notEndLast: true, color: 'transparent'}}
      onFiltersChange={handleOnFiltersChange}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      numberOfItems={numberOfItems}
      xs={xs}
      apply={apply}
      {...props}
    >
      {getFilterValue('date-filter') && (
        <DateFilter filter={{startDate, endDate}} onDateChange={handleDates} />
      )}
      {getFilterValue('plants-filter') && (
        <PlantsDropdown
          onLight
          error={plantsError}
          plants={plants}
          onChange={handlePlants}
          selectedPlantId={selectedPlantId}
        />
      )}
      {getFilterValue('products-filter') && (
        <ProductsDropdown
          onLight
          error={productsError}
          products={products}
          onChange={handleProducts}
          selectedProductId={selectedProductId}
        />
      )}
    </GenericFilterBar>
  )
}
