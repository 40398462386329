/* eslint-disable complexity */
import {User} from '@hconnect/apiclient/src'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {
  ErrorText,
  Page,
  lastThirtyDays,
  ResponsiveTable,
  Typography,
  getDateRangeInDays,
  dateRangeExceedsOneYear,
  dateRangeFormatter
} from '@hconnect/uikit'
import {Overlay} from '@hconnect/uikit/src/lib/Shell/Overlay'
import {Box, Grid, Paper, Slide} from '@mui/material'
import classNames from 'classnames'
import {keys} from 'lodash'
import assignIn from 'lodash/assignIn'
import get from 'lodash/get'
import moment from 'moment'
import queryString from 'query-string'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {v4 as uuidV4} from 'uuid'

import SelectReport from '../../../Assets/select-report.svg'
import {
  ApiDownloadRequestFormats,
  ApiDownloadRequestTypes,
  SheetRequestType
} from '../../../AsyncJobQueue/AsyncJobQueue.types'
import {PageNames} from '../../../common/constants'
import Content from '../../../Molecules/Content'
import {
  CustomerFeedbackBanner,
  CustomerFeedbackPanel
} from '../../../Molecules/CustomerFeedbackProgramme'
import {DocumentActionsBar} from '../../../Molecules/DocumentActionsBar'
import {ExportDropdown, ExportScheduledInfo, ScheduledExport} from '../../../Molecules/Export/'
import {InformationBanner} from '../../../Molecules/InformationBanner/InformationBanner'
import {
  ResponsiveHeight,
  useResponsiveBreakpoints,
  useResponsiveGridStyles
} from '../../../Molecules/Responsive.utils'
import {ResultsForMessage} from '../../../Molecules/ResultsForMessage'
import {selectSelectedCustomer} from '../../../Organisms/Customers'
import {Customer} from '../../../Organisms/Customers/Customers.types'
import {MaterialTestPreview} from '../../../Organisms/Materialtest'
import {AppState} from '../../../Root.store'

import {MaterialTestDetails, SelectedMaterialTestsAside} from './Components'
import Filters from './Filters'
import {MaterialTestColumns} from './MaterialTest.columns'
import {LookupSelectItem, MaterialTestView, MaterialTestViews} from './MaterialTest.enum'
import {LookupFilterType} from './MaterialTest.filters'

interface Rmc {
  fetchMaterialTests: (analyticsId?: string, userId?: string) => void
  items: MaterialTestPreview[]
  isFetching: boolean
  customerId?: string
  downloadDocument: (documentId: string, fileName: string) => void
  error: any
  errorsClear: any
  dateFilter: any
  setDefaultDateFilter: (props: any) => void
  filters: any
  defaultFilters: any
  sortOrder: {key: string; asc: boolean}
  setSortKey: (sortKey: string) => void
  showLoadMore: boolean
  loadMore: () => void
  setLookupFilter: (props: LookupFilterType) => void
  switchToLookup: () => void
  view: MaterialTestViews
  sheetRequest: (request: SheetRequestType) => void
}

export const Rmc: React.FC<Rmc> = (props) => {
  const {
    fetchMaterialTests,
    items,
    isFetching,
    error,
    customerId,
    dateFilter,
    setDefaultDateFilter,
    filters,
    defaultFilters,
    sortOrder: {key: sortedBy, asc: sortAscending},
    setSortKey,
    setLookupFilter,
    switchToLookup,
    view,
    sheetRequest
  } = props
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {classes: responsiveClasses} = useResponsiveGridStyles()
  const {screenSize, smallScreen, largeScreen} = useResponsiveBreakpoints()
  const location = useLocation()
  const history = useHistory()
  const query = queryString.parse(location.search)

  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)
  const getEmptyMessage = (loading: boolean) => (loading ? t('loading') : t('noData'))
  const [selectedRow, setSelectedRow] = useState<MaterialTestPreview>()
  const [selectedRows, setSelectedRows] = useState<MaterialTestPreview[]>([])
  const [showSlide, setShowSlide] = useState<boolean>(false)
  const [showCustomerPanel, setShowCustomerPanel] = React.useState<boolean>(false)
  const [scheduledExport, setScheduledExport] = React.useState<ScheduledExport | undefined>(
    undefined
  )
  const selectedCustomer = useSelector<AppState, Customer | undefined>((state: AppState) =>
    selectSelectedCustomer(state, customerId)
  )
  const lookupAnalyticsId = uuidV4()
  const {host} = (window as Window).location
  const url = `${RegExp(/localhost/).exec(host) ? 'http' : 'https'}://${host}/downloadRequests`
  useEffect(() => {
    if (selectedRow || showCustomerPanel) {
      setShowSlide(true)
    }
  }, [selectedRow, showCustomerPanel])

  useEffect(() => {
    const [startDate, endDate] = lastThirtyDays(language)
    if (query && query.orderNumber) {
      switchToLookup()
      setLookupFilter({entity: LookupSelectItem.ORDER, value: query.orderNumber as string})
      // TODO: set orderNumber filter
    } else {
      setDefaultDateFilter({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchConditions = [sortedBy, sortAscending, filters, defaultFilters, customerId]
  const isLookupActive = view === MaterialTestView.LOOKUP
  useEffect(() => {
    if (customerId) {
      if (!isLookupActive) {
        history.replace({
          search: ''
        })
      }
      fetchMaterialTests()
      setSelectedRow(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, fetchConditions)

  const startDate = get(dateFilter, 'startDate')
  const endDate = get(dateFilter, 'endDate')

  const resultsForMessage = [dateRangeFormatter(startDate, endDate, language)]

  const {site, lookup} = filters

  const bulkDownload = (testIds: string[], includeTickets: boolean) => {
    const {name: fullName, eMail, country: userCountry} = user || {}
    const downloadJobId = uuidV4()
    trackEvent('hubBulkDownloadStart', {
      product: 'hub',
      jobId: downloadJobId,
      numberOfDaysSelected: getDateRangeInDays(startDate, endDate),
      initiationSource: 'hub',
      ordersInView: items.length,
      totalItemsCount: items.length,
      filterCustomer: customerId,
      filterShipTo: site?.siteId,
      filterDateRange: {startDate: startDate, endDate: endDate},
      noOfFileSelected: testIds.length
    })
    sheetRequest({
      criteria: {testIds, includeTickets},
      type: ApiDownloadRequestTypes.bulkMaterialTestDownload,
      format: ApiDownloadRequestFormats.zip,
      name: `material-tests-${moment().format('YYYY-MM-DD-HH-mm-ss')}.zip`,
      shortName: `material-tests-${moment().format('YYYY-MM-DD-HH-mm-ss')}`,
      email: eMail || '',
      sendEmail: true,
      fullName: fullName || '',
      url,
      country: userCountry || '',
      clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      analytics: {
        jobId: downloadJobId,
        numberOfDaysSelected: getDateRangeInDays(startDate, endDate),
        initiationSource: 'hub'
      }
    })
  }

  const sideContent = showCustomerPanel ? (
    <CustomerFeedbackPanel
      close={() => setShowCustomerPanel(false)}
      entryPoint={PageNames.TEST_REPORTS}
    />
  ) : selectedRows.length > 0 ? (
    <SelectedMaterialTestsAside
      materialTests={selectedRows}
      t={t}
      bulkDownload={bulkDownload}
      close={() => setSelectedRows([])}
    />
  ) : selectedRow ? (
    <MaterialTestDetails
      materialTest={selectedRow}
      startDate={startDate}
      endDate={endDate}
      close={() => setSelectedRow(undefined)}
    />
  ) : null

  const handleRowClick = (row) => {
    setShowCustomerPanel(false)
    setSelectedRow(row)
  }

  const isExportDisabled = () =>
    !(items && items.length) ||
    (dateFilter && dateRangeExceedsOneYear(dateFilter.startDate, dateFilter.endDate))

  const getCriteria: () => Record<string, string> = () => {
    return assignIn(
      {
        ...(!isLookupActive ? {startDate} : {}),
        ...(!isLookupActive ? {endDate} : {}),
        ...(site ? {siteId: site.siteId} : {}),
        ...(query && query.orderNumber ? {orderNumber: query.orderNumber} : {}),
        customerId: customerId,
        sortedBy: `${sortedBy} ${sortAscending ? 'asc' : 'desc'}`
      },
      ...keys(filters).map((filter: any) => {
        const filterContent = get(filters, [filter], null)
        if (filter === 'lookup') {
          if (filterContent.entity === LookupSelectItem.ORDER) {
            return {orderNumber: filterContent.value}
          }
          if (filterContent.entity === LookupSelectItem.TICKET) {
            return {orderNumber: items[0].orderNumber}
          }
        }
        return filterContent && !['date'].includes(filter) ? {...filterContent} : null
      })
    )
  }

  return (
    <Content>
      <Page
        title={t('materialTest.title')}
        boxed={false}
        variant={smallScreen ? 'default' : 'withDetails'}
        headerActionContent={<div />}
        {...(smallScreen ? {px: 2, py: 0} : {py: 0})}
      >
        <Filters lookupAnalyticsId={lookupAnalyticsId} userId={user?.user_id || ''} />
        <Grid
          container
          spacing={2}
          style={{
            position: 'relative',
            marginTop: 0,
            minHeight: largeScreen ? ResponsiveHeight : 'initial',
            overflowY: 'auto'
          }}
        >
          <Grid
            item
            xs={12}
            lg={8}
            style={{
              maxHeight: ResponsiveHeight,
              paddingTop: 0,
              paddingBottom: 0,
              overflowY: 'auto'
            }}
          >
            <Paper elevation={4} className={responsiveClasses.gridItem} style={{height: '100%'}}>
              {error && (
                <ErrorText
                  label={t(error.translationKey)}
                  showRetry={error?.callback}
                  onRetry={error.callback}
                />
              )}

              <ExportScheduledInfo scheduledExport={scheduledExport} />

              {!error && !smallScreen && selectedCustomer && (
                <DocumentActionsBar>
                  <Box />
                  <ExportDropdown
                    dataTestId="materialtest-export-dropdown"
                    disabled={isFetching}
                    oneTimeExportDisabled={isExportDisabled()}
                    downloadType={ApiDownloadRequestTypes.materialTestExport}
                    getCriteria={getCriteria}
                    customer={selectedCustomer}
                    entryPoint="materialTests"
                    trackingParams={{
                      filterShipTo: site?.siteId || '',
                      filterDateRangeStart: startDate,
                      filterDateRangeEnd: endDate,
                      numberOfDaysSelected: getDateRangeInDays(startDate, endDate),
                      lookUpQuery: isLookupActive ? lookup.value : '',
                      lookUpCategory: isLookupActive ? lookup.entity : ''
                    }}
                    onExportScheduled={setScheduledExport}
                  />
                </DocumentActionsBar>
              )}
              {!error && <ResultsForMessage messageList={resultsForMessage} />}
              {!error && (
                <Box px={2}>
                  <InformationBanner
                    message={t('checkboxInformationBanner.certificates')}
                    pageName="testReports"
                    tableHasData={!isFetching && items?.length > 0}
                  />
                  <ResponsiveTable
                    data-test-id="certificate-data-table"
                    columns={MaterialTestColumns(t, language)}
                    rows={items}
                    keyField="orderNumber"
                    loading={isFetching}
                    onSort={(e, sortKey: string) => {
                      setSortKey(sortKey)
                    }}
                    sortedBy={sortedBy}
                    sortingOrder={sortAscending ? 'asc' : 'desc'}
                    emptyMessage={
                      <Typography variant="caption">{getEmptyMessage(isFetching)}</Typography>
                    }
                    onRowClick={(row) => handleRowClick(row)}
                    rowDetailsAvailable
                    rowDetailsClosed={selectedRow === undefined}
                    selectionEnabled={true}
                    isRowSelectable={() => true}
                    getSelectedRows={(rows) => setSelectedRows([...rows])}
                    rowHeight={58}
                    promoBanner={
                      <CustomerFeedbackBanner
                        setKnowMore={(val: boolean) => setShowCustomerPanel(val)}
                        entryPoint={PageNames.TEST_REPORTS}
                      />
                    }
                  />
                </Box>
              )}
            </Paper>
            {screenSize === 'md' && sideContent ? (
              <Box
                position="absolute"
                right={0}
                top={0}
                height="100%"
                width={2 / 5}
                maxHeight={ResponsiveHeight}
              >
                <Slide
                  in={Boolean(showSlide)}
                  onExited={() => setSelectedRow(undefined)}
                  direction="left"
                  mountOnEnter
                  unmountOnExit
                  data-test-id={`slide-${selectedRow?.orderNumber || 'selected-material-tests'}`}
                  className={responsiveClasses.gridItem}
                >
                  <Paper
                    elevation={4}
                    color="white"
                    sx={{height: '100%', maxHeight: ResponsiveHeight, overflowY: 'auto', p: 4}}
                  >
                    {sideContent}
                  </Paper>
                </Slide>
              </Box>
            ) : null}
          </Grid>
          {largeScreen && (
            <Grid
              item
              lg={4}
              style={{
                maxHeight: ResponsiveHeight,
                paddingTop: 0,
                paddingBottom: 0,
                overflowY: 'auto'
              }}
              data-test-id={`material-tests-${selectedRow?.orderNumber}`}
            >
              <Paper
                elevation={4}
                color="white"
                className={responsiveClasses.gridItem}
                style={{height: '100%', overflowY: 'auto'}}
                sx={{p: largeScreen ? 4 : 2}}
              >
                {sideContent ? (
                  sideContent
                ) : (
                  <Box
                    style={{
                      height: '100%',
                      display: 'grid',
                      justifyItems: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Box>
                      <img src={SelectReport} alt="Select report" />
                      <Typography style={{maxWidth: 250}}>
                        {t('materialTest.placeholder')}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Paper>
            </Grid>
          )}
        </Grid>
      </Page>
      <Overlay
        data-test-id="sm-details-opened"
        isVisible={Boolean((selectedRow || showCustomerPanel) && smallScreen)}
        overlayClassName={responsiveClasses.overlay}
        contentClassName={classNames(responsiveClasses.overlayContent, responsiveClasses.gridItem)}
      >
        {selectedRow && (
          <MaterialTestDetails
            materialTest={selectedRow}
            startDate={startDate}
            endDate={endDate}
            close={() => setSelectedRow(undefined)}
          />
        )}
        {showCustomerPanel && (
          <CustomerFeedbackPanel
            close={() => setShowCustomerPanel(false)}
            entryPoint={PageNames.TEST_REPORTS}
          />
        )}
      </Overlay>
    </Content>
  )
}
